<template lang="">
  <div>
    <div
      v-if="getPaymentMethod"
      class="text-subtitle-1 primary--text font-weight-bold pb-2"
    >
      Forma de Pago: {{ getPaymentMethod }}
    </div>
    <v-sheet
      v-if="isMercadoPagoEnabled"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="MercadoPago"
      @click="setPaymentMethod('Mercado Pago')"
    >
      <v-row>
        <v-col cols="12">
          <v-row justify="center" no-gutters align-content="space-between">
            <v-img
              max-width="5.75rem"
              :src="require('@/assets/img/payment-methods/mercado-pago.png')"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      v-if="isModoEnabled && hasToShowMobileContent"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="Modo"
      @click="setPaymentMethod('Modo')"
    >
      <v-row>
        <v-col cols="12">
          <v-row justify="center" no-gutters align-content="space-between">
            <v-img
              max-width="10.75rem"
              :src="require('@/assets/img/payment-methods/nave-modo-tn.svg')"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      v-if="isModoEnabled"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="Nave"
      @click="setPaymentMethod('Nave')"
    >
      <v-row>
        <v-col cols="12">
          <v-row justify="center" no-gutters align-content="space-between">
            Pago de Tarjeta Débito o Crédito
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet
      v-if="isCashEnabled && isWebOrdersModeEnabled"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="Efectivo"
      @click="setPaymentMethod('Efectivo')"
    >
      <v-col cols="12">
        <v-row justify="center" no-gutters align-content="space-between">
          <v-icon color="black" class="cashIcon">mdi-cash-multiple</v-icon>
          {{ getDeliveryMethod == "pickup" ? "Pago en local" : "Efectivo" }}
        </v-row>
      </v-col>
    </v-sheet>

    <v-sheet
      v-if="isKioskModeEnabled"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="PointPlus"
      @click="setPaymentMethod('PointPlus')"
    >
      <v-row>
        <v-col cols="12">
          <v-row justify="center" no-gutters aling="center">
            <span class="logo-container">
              <v-img
                max-width="3rem"
                :src="
                  require('@/assets/img/payment-methods/Mercado_Pago_Logo.svg')
                "
              />
            </span>
            <span class="payment-text">Pago de Tarjeta Débito o Crédito</span>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      v-if="isOtherPaymentWayEnabled"
      class="pa-6 d-flex paymentMethod"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
      ref="Other"
      @click="setPaymentMethod('Otra forma de pago')"
    >
      <v-col cols="12">
        <v-row justify="center" no-gutters align-content="space-between">
          Otra forma de pago
        </v-row>
      </v-col>
    </v-sheet>
  </div>
</template>
<script>
import mobileCommon from "@/mixins/mobile";
import shopUtils from "@/mixins/shopUtils";
export default {
  mixins: [mobileCommon, shopUtils],
  computed: {
    getPaymentMethod() {
      return this.$store.getters["deliveryCart/getPaymentMethod"];
    },
    isKioskModeEnabled() {
      return this.$store.state.menu.profile.isKioskModeEnabled;
    },
    isMercadoPagoEnabled() {
      const result = this.$store.state.menu.profile.isMercadoPagoEnabled;
      if (result == undefined) return true;
      else return result;
    },
    isModoEnabled() {
      return this.$store.state.menu.profile.isModoEnabled;
    },
    isCashEnabled() {
      const result = this.$store.state.menu.profile.isCashEnabled;
      if (result == undefined) return false;
      else return result;
    },
    isOtherPaymentWayEnabled() {
      return (
        this.$store.state.menu.profile.isOtherPaymentWayEnabled &&
        this.isKioskModeEnabled
      );
    },
  },
  watch: {
    getPaymentMethod() {
      this.setGlowingEffect(this.getPaymentMethod);
    },
  },
  mounted() {
    if (this.isMercadoPagoEnabled) {
      this.setPaymentMethod("Mercado Pago");
      this.setGlowingEffect("Mercado Pago");
    } else if (this.isOtherPaymentWayEnabled) {
      this.setPaymentMethod("Otra forma de pago");
      this.setGlowingEffect("Otra forma de pago");
    } else if (this.isModoEnabled && this.hasToShowMobileContent) {
      this.setPaymentMethod("Modo");
      this.setGlowingEffect("Modo");
    } else if (this.isModoEnabled) {
      this.setPaymentMethod("Nave");
      this.setGlowingEffect("Nave");
    } else if (this.isCashEnabled) {
      this.setPaymentMethod("Efectivo");
      this.setGlowingEffect("Efectivo");
    }
  },
  methods: {
    setGlowingEffect(paymentMethod) {
      this.resetAllGlowingEffect();
      switch (paymentMethod) {
        case "Mercado Pago":
          this.setGlowingEffectByPaymentMethod("Mercado Pago", "MercadoPago");
        case "Otra forma de pago":
          this.setGlowingEffectByPaymentMethod("Otra forma de pago", "Other");
        case "Modo":
          this.setGlowingEffectByPaymentMethod("Modo", "Modo");
        case "Nave":
          this.setGlowingEffectByPaymentMethod("Nave", "Nave");
        case "Efectivo":
          this.setGlowingEffectByPaymentMethod("Efectivo", "Efectivo");
        case "PointPlus":
          this.setGlowingEffectByPaymentMethod("PointPlus", "PointPlus");
      }
    },
    resetAllGlowingEffect() {
      this.resetGlowingEffect("MercadoPago");
      this.resetGlowingEffect("Other");
      this.resetGlowingEffect("Modo");
      this.resetGlowingEffect("Nave");
      this.resetGlowingEffect("Efectivo");
      this.resetGlowingEffect("PointPlus");
    },
    resetGlowingEffect(ref) {
      let classes =
        "pa-6 d-flex paymentMethod v-sheet v-sheet--outlined v-sheet--shaped theme--light elevation-4 rounded-lg white";
      const elRef = this.$refs[ref];
      if (elRef) elRef.$el.classList.value = [classes];
    },
    setGlowingEffectByPaymentMethod(paymentMethod, ref) {
      let classes =
        "pa-6 d-flex paymentMethod v-sheet v-sheet--outlined v-sheet--shaped theme--light elevation-4 rounded-lg white";
      let selectedPaymentMethodClass = " selectedPaymentMethod";

      if (this.getPaymentMethod == paymentMethod)
        classes += selectedPaymentMethodClass;
      const elRef = this.$refs[ref];
      if (elRef) elRef.$el.classList.value = [classes];
    },
    setPaymentMethod(paymentMethod) {
      this.$store.dispatch("deliveryCart/setPaymentMethod", paymentMethod);
    },
  },
};
</script>
<style lang="scss" scoped>
.paymentMethod {
  margin: 10px 0px;
  cursor: pointer;
}
.selectedPaymentMethod {
  background-color: #fff !important;

  box-shadow: 0 0 10px #00a3e2 !important;
}
.cashIcon {
  padding-right: 7px;
}
.payment-text {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.logo-container {
  display: flex;
  align-items: center;
}
</style>
